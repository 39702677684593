import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';
import { projectConfigState } from 'recoil/Academy/projectConfigState';
import { useCurrentUser } from 'app.hooks/useCurrentUser';
import { useExamLikeHandler } from 'app.hooks/useExamLikeHandler';
import { convertExamQueryString } from 'app.feature/academy/module/convertExamQueryString';
import ExamApi from 'lib/api/exam';
import { TExams } from 'app.type/exams';
import { TPaginationData } from 'app.type/common';
import FavoriteIconSvg from 'app.feature/academy/component/icon/heart-icon-regular.svg';
import FavoriteIconSolidSvg from 'app.feature/academy/component/icon/heart-icon-solid.svg';

const CbtListMobile = () => {
  const router = useRouter();
  const { user } = useCurrentUser();
  const { logo, handleLoginRedirect, handleLikeButtonClick } = useRecoilValue(projectConfigState);

  const type = router.query?.type ? String(router.query.type) : '';
  const queryKey = [
    type ? `/apiV2/exams/${type}` : '/apiV2/exams',
    convertExamQueryString(router.query),
  ];

  const { data: exams = { totalCount: 0, nodes: [] } } = useQuery<TPaginationData<TExams>>({
    queryKey,
    queryFn: () =>
      type
        ? ExamApi.getExamsByType(type, convertExamQueryString(router.query))
        : ExamApi.getExamList(convertExamQueryString(router.query)),
  });

  const examList = exams.nodes;

  const { handleLikeExam } = useExamLikeHandler(queryKey, (oldData, viewerHasLiked, examIdx) => {
    if (Array.isArray(oldData.nodes)) {
      const updatedNodes = oldData.nodes.map((exam: any) =>
        exam.examIdx === examIdx
          ? {
              ...exam,
              likeCount: exam.likeCount + (viewerHasLiked ? -1 : 1),
              viewerHasLiked: !viewerHasLiked,
            }
          : exam
      );
      return { ...oldData, nodes: updatedNodes };
    }

    return {
      ...oldData,
      likeCount: (oldData?.likeCount || 0) + (viewerHasLiked ? -1 : 1),
      viewerHasLiked: !viewerHasLiked,
    };
  });

  const onLikeButtonClick = async (examIdx: number, viewerHasLiked: boolean) => {
    if (!user) {
      router.push(handleLoginRedirect(window.location.href));
      return;
    }

    await handleLikeExam(examIdx, viewerHasLiked);

    if (handleLikeButtonClick) {
      handleLikeButtonClick(examIdx);
    }
  };

  return (
    <StyledWrapper>
      {examList?.length > 0 ? (
        examList.map((exam: TExams, index: number) => (
          <div className="list-wrapper">
            <Link href={`/exam/detail/${exam.examIdx}`} className="list-link">
              <div className="img-wrapper">
                {exam.examUrl ? (
                  <img src={exam.examUrl} alt={exam.examName} />
                ) : (
                  <div className="empty-img">
                    <img src={logo} alt="" />
                  </div>
                )}
              </div>
              <div className="text-wrapper">
                <small className="institution">
                  {exam.institution ? exam.institution : '시행기관'}
                </small>
                <h1>{exam.examName}</h1>
                <div className="text-box">
                  <small>총회차: {Number(exam.examDetailCount).toLocaleString()}</small>
                  <small>총 문제 수: {Number(exam.problemCount).toLocaleString()}</small>
                  <small>조회수: {Number(exam.viewCount).toLocaleString()}</small>
                </div>
              </div>
            </Link>
            <div className="favorite">
              <button onClick={() => onLikeButtonClick(exam.examIdx, exam.viewerHasLiked)}>
                {exam.viewerHasLiked ? <FavoriteIconSolidSvg /> : <FavoriteIconSvg />}
              </button>
            </div>
          </div>
        ))
      ) : (
        <p className="empty">검색 결과가 없습니다.</p>
      )}
    </StyledWrapper>
  );
};

export default CbtListMobile;

const StyledWrapper = styled.div`
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  .list-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid #f4f4f4;
    .list-link {
      width: 100%;
      display: flex;
      .img-wrapper {
        width: 30%;
        max-width: 60px;
        img {
          width: 100%;
        }
      }
      .text-wrapper {
        width: 80%;
        max-width: calc(100% - 80px);
        margin: 0 24px 0 15px;
        .institution {
          font-size: 12px;
          font-weight: 400;
          color: #333;
        }
        h1 {
          font-size: 16px;
          font-weight: 400;
          color: #333;
          margin: 10px 0;
        }
        .text-box {
          display: flex;
          justify-content: flex-start;
          gap: 16px;
          small {
            font-size: 12px;
            color: #333;
            position: relative;
            &:after {
              content: '';
              width: 1px;
              height: 7px;
              background-color: #ddd;
              position: absolute;
              right: -9px;
              top: 50%;
              transform: translateY(-50%);
            }
            &:last-child {
              margin-right: 0;
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }

    .favorite {
      text-align: center;
      button {
        background: none;
        border: none;
        cursor: pointer;
      }
      span {
        display: block;
        margin-top: 5px;
        line-height: initial;
        font-size: 16px;
        color: #999;
      }
    }

    .empty-img {
      width: 60px;
      height: 60px;
      border-radius: 20px;
      background-color: #f4f4f4;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 80%;
        height: auto;
      }
    }
  }
`;

const EmptyList = styled.div`
  padding: 76px 0;
  text-align: center;
  p {
    font-size: 20px;
    color: #333;
    font-weight: 400;
  }
  span {
    font-size: 16px;
    color: #999;
    font-weight: 300;
  }
`;
