import styled from 'styled-components';
import Header from 'app.layout/header/Header';
import Footer from 'app.layout/footer/Footer';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';

const AppWeb = ({ pageProps, Component }: any) => {
  const router = useRouter();

  // cbt 페이지에서는 헤더, 푸터를 삭제함
  const cbtPage = [
    '/cbt/problem/[multipleProblemIdx]',
    '/cbt/onequestions/[examDetailIdx]',
    '/cbt/onequestions/random/[examIdx]',
    '/cbt/onequestions/review',
    '/cbt/practice',
    '/cbt/practice/[examDetailIdx]',
    '/cbt/practice/random/[examIdx]',
    '/cbt/practice/random/[examIdx]/score',
    '/cbt/practice/random/[examIdx]/solution',
    '/cbt/practice/score/[recordIdx]',
    '/cbt/practice/result/[recordIdx]',
    '/cbt/practice/solution/[recordIdx]',
    '/cbt/select/[examDetailIdx]',
    '/cbt/select/random/[examIdx]',
    '/refund-login',
    '/refund-login/local',
  ];

  const webViewPage = [
    '/',
    '/exam/[type]',
    '/exam/detail/[examIdx]',
    '/mycbt/archive',
    '/mycbt/review',
    '/mycbt/note',
  ];

  const isDisabledHeader = pageProps.isWebView && webViewPage.includes(router.pathname);

  if (isDisabledHeader) {
    return (
      <Container>
        <Component {...pageProps} />
        <Footer />
      </Container>
    );
  }

  if (cbtPage.includes(router.pathname)) {
    return <Component {...pageProps} />;
  }

  return (
    <Container>
      <Header />
      <Component {...pageProps} />
      <Footer />
    </Container>
  );
};

export default AppWeb;

const Container = styled.div`
  width: 100%;
`;
