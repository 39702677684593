import React from 'react';
import styled from 'styled-components';
import HamburgerSvg from 'src/app.feature/academy/component/icon/hamburger-icon.svg';

const CategoryButton = ({ name, url, handleClickParentCategory }: any) => {
  return (
    <StyledWrapper onClick={() => handleClickParentCategory(url)}>
      <HamburgerSvg />
      {name}
    </StyledWrapper>
  );
};

export default CategoryButton;

const StyledWrapper = styled.button`
  min-width: 140px;
  padding: 0px 33px;
  font-size: 17px;
  font-weight: 600;
  color: #333;
  svg {
    margin-right: 16px;
  }
`;
