import { axiosClient } from 'lib/api/axios';
import qs from 'qs';

const ExamApi = {
  getExamTypes: async () => {
    try {
      const response = await axiosClient.get('/apiV2/exam-types');
      if (response.status === 401) return null;
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getExamList: async (
    query: {
      examTypeIds?: number | number[];
      pageSize?: number;
      page?: number;
      sort?: string;
      order?: string;
      keyword?: string;
    } = {}
  ) => {
    try {
      const response = await axiosClient({
        method: 'GET',
        url: '/apiV2/exams' + qs.stringify(query, { addQueryPrefix: true, indices: false }),
      });

      if (response.status === 401) return null;
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getExamsByType: async (
    type?: string,
    query?: {
      pageSize?: number;
      page?: number;
      sort?: string;
      order?: string;
      keyword?: string;
    }
  ) => {
    try {
      const response = await axiosClient({
        method: 'GET',
        url: `/apiV2/exams/${type}` + qs.stringify(query, { addQueryPrefix: true, indices: false }),
      });

      if (response.status === 401) return null;
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getExamInfo: async (examIdx: number) => {
    try {
      const response = await axiosClient.get(`/apiV2/exams/${examIdx}`);
      if (response.status === 401) return null;
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getExamDetails: async (examIdx: number, query: any) => {
    try {
      const response = await axiosClient({
        method: 'GET',
        url: `/apiV2/exams/${examIdx}/exam-details` + qs.stringify(query, { addQueryPrefix: true, skipNulls: true }),
      });
      if (response.status === 401) return null;
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getBestExam: async () => {
    try {
      const response = await axiosClient.get('/apiV2/exams/best');
      if (response.status === 401) return null;
      return response.data;
    } catch (error) {
      throw error;
    }
    return '';
  },

  likeExam: async (examIdx: number) => {
    try {
      const response = await axiosClient({ method: 'POST', url: `/apiV2/exams/${examIdx}/like` });
      if (response.status === 401) return null;
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  unlikeExam: async (examIdx: number) => {
    try {
      const response = await axiosClient({ method: 'POST', url: `/apiV2/exams/${examIdx}/unlike` });
      if (response.status === 401) return null;
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getCommunityBoard: async (examIdx: number) => {
    try {
      const response = await axiosClient.get(`/apiV2/exams/${examIdx}/community-board`);
      if (response.status === 401) return null;
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default ExamApi;
