import { Component, ComponentProps, ElementType, ReactElement } from 'react';
import { atom, selector } from 'recoil';

export interface ModalRootType {
  key: string;
  Component: () => ReactElement;
}

const modalRootAtom = atom<ModalRootType[]>({
  key: 'modalRootKey',
  default: [],
});

export const modalRootSelector = selector({
  key: 'modalRootSelector',
  get: ({ get }) => get(modalRootAtom),
  set: ({ set }, newValue) => {
    set(modalRootAtom, newValue as ModalRootType[]);
  },
});
