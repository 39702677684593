import { atom, selector } from 'recoil';

const CategoryAtoms = atom<boolean>({
  key: 'categoryAtoms',
  default: false,
});

export const CategoryState = selector({
  key: 'categoryState',
  get: ({ get }) => get(CategoryAtoms),
  set: ({ set }, newValue) => {
    set(CategoryAtoms, newValue as boolean);
  },
});
