import axiosClient from "./api";
import { NURSEEDU_BASE_URL } from "../constant/nurseEduConfig";
import { handleError } from "./error";

export const handleNurseEduPurchase = async (examIdx: number) => {
  const { data } = await axiosClient.get(`/cbt-product/exams/${examIdx}`);
  const { optionId, shopbyProductId } = data;

  try {
    const { data: { orderSheetNo } }  = await axiosClient.post(`/v2/orders`, {
      products: [{
        productNo: shopbyProductId,
        optionNo: optionId,
        orderCnt: 1,
      }],
    });

    const url = `${NURSEEDU_BASE_URL}/orders/order/${orderSheetNo}`;
    window.location.href = url;

  } catch (e) {    
    handleError(e)
  }
}
