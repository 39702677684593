import styles from './styles.module.scss';
import { getProjectConfigByHost } from 'src/app.core/common/module/getProjectConfigByHost';
import Link from 'next/link';
import { isAxiosError } from 'node_modules/axios';
import Router from 'next/router';

interface Props<ErrorType extends Error = Error> {
  error: ErrorType;
  reset: () => void;
  host: string;
}

export default function ErrorHelper({ error, reset, host }: Props) {
  const projectConfig = getProjectConfigByHost(host);
  const { logo, projectNameKR, clientBaseUrl } = projectConfig;

  if (isAxiosError(error)){
    const status = error.response?.status;

    if (status === 401 || status === 403) {
      alert("해당 페이지에 접근 권한이 없습니다.\n이전 페이지로 이동합니다.");
      Router.back();
    }
  }

  return (
    <div className={styles.errorBoundaryRoot}>
      {logo ? (
        <div className={styles.logo}>
          <Link href={clientBaseUrl}>
            <img src={logo} alt={projectNameKR} />
          </Link>
        </div>
      ) : (
        ''
      )}
      <div className={styles.title}>오류가 발생하였습니다</div>
      <div className={styles.content}>
        <div>페이지 새로고침으로 해결되지 않는다면,</div>
        <div>고객 문의를 통해 문의사항을 남겨주세요.</div>
      </div>
      <button onClick={reset} className={styles.refreshBtn}>
        새로고침
      </button>
    </div>
  );
}
