import { ProjectConfig } from 'app.core/common/module/projectConfigs';
import FooterNurseEdu from '../component/Footer';
import HeaderNurseEdu from '../component/Header';
import { NurseEduSeoConfig } from '../component/seo/SEONurseEdu';
import { handleNurseEduPurchase } from '../lib/purchase';

const nurseEduSeo = new NurseEduSeoConfig();

export const NurseEduHosts = [
  'cbt.nurse-edu.co.kr',
  'cbt-dev.nurse-edu.co.kr',
  'cbt-local.nurse-edu.co.kr',
];

export const NurseEduProject: { [key in 'production' | 'development' | 'test']: ProjectConfig } = {
  development: {
    projectName: 'NurseEdu',
    projectNameKR: '널스에듀',
    clientBaseUrl: 'https://dev.nurse-edu.co.kr',
    handleLoginRedirect: (url?: string) => {
      if (url) return `https://dev.nurse-edu.co.kr/login?redirect=${encodeURIComponent(url)}`;
      return `https://dev.nurse-edu.co.kr/login`;
    },
    logoutRedirectUri: 'https://apiv2-dev.nurse-edu.co.kr/logout',
    apiBaseUrl: 'https://apiv2-dev.nurse-edu.co.kr',
    apiBaseProxyUrl: 'https://apiv2-dev.nurse-edu.co.kr/cbt',
    communityUrl: 'https://nurselink.mnpp.cc',
    idp: 'nurseEdu',
    logo: '/images/logo/nurseEdu/logo.svg',
    logoImagePng: '/images/logo/nurseEdu/logo(800X800).png',
    googleTagManagerId: '',
    kakaoServiceKey: '642ff75d4ef2a326ecb835840c782725',
    handlePurchase: handleNurseEduPurchase,
    header: HeaderNurseEdu,
    footer: FooterNurseEdu,
    defaultSeo: () => nurseEduSeo.defaultSeo() || {},
    examListSeo: (type) => nurseEduSeo.examListSeo(type),
    examDetailSeo: (data) => nurseEduSeo.examDetailSeo(data),
    examTypeSelectionSeo: (data) => nurseEduSeo.examTypeSelectionSeo(data),
    cbtOneQuestionTypeSeo: (data) => nurseEduSeo.cbtOneQuestionTypeSeo(data),
    cbtPracticeTypeSeo: (data) => nurseEduSeo.cbtPracticeTypeSeo(data),
    examResultSeo: (data) => nurseEduSeo.examResultSeo(data),
    examSolutionSeo: (data) => nurseEduSeo.examSolutionSeo(data),
    myCBTArchiveSeo: () => nurseEduSeo.myCBTArchiveSeo(),
    myCBTResultSeo: () => nurseEduSeo.myCBTResultSeo(),
    myCBTNoteSeo: () => nurseEduSeo.myCBTNoteSeo(),
    myCBTPurchaseSeo:  () => nurseEduSeo.myCBTPurchaseSEO()
  },

  production: {
    projectName: 'NurseEdu',
    projectNameKR: '널스에듀',
    clientBaseUrl: 'https://nurse-edu.co.kr',
    handleLoginRedirect: (url?: string) => {
      if (url) return `https://nurse-edu.co.kr/login?redirect=${encodeURIComponent(url)}`;
      return `https://nurse-edu.co.kr/login`;
    },
    logoutRedirectUri: 'https://api.nurse-edu.co.kr/logout',
    apiBaseUrl: 'https://api.nurse-edu.co.kr',
    apiBaseProxyUrl: 'https://api.nurse-edu.co.kr/cbt',
    communityUrl: 'https://nurselink.moneple.com',
    idp: 'nurseEdu',
    logo: '/images/logo/nurseEdu/logo.svg',
    logoImagePng: '/images/logo/nurseEdu/logo(800X800).png',
    googleTagManagerId: '',
    kakaoServiceKey: '642ff75d4ef2a326ecb835840c782725',
    handlePurchase: handleNurseEduPurchase,
    header: HeaderNurseEdu,
    footer: FooterNurseEdu,
    defaultSeo: () => nurseEduSeo.defaultSeo() || {},
    examListSeo: (type) => nurseEduSeo.examListSeo(type),
    examDetailSeo: (data) => nurseEduSeo.examDetailSeo(data),
    examTypeSelectionSeo: (data) => nurseEduSeo.examTypeSelectionSeo(data),
    cbtOneQuestionTypeSeo: (data) => nurseEduSeo.cbtOneQuestionTypeSeo(data),
    cbtPracticeTypeSeo: (data) => nurseEduSeo.cbtPracticeTypeSeo(data),
    examResultSeo: (data) => nurseEduSeo.examResultSeo(data),
    examSolutionSeo: (data) => nurseEduSeo.examSolutionSeo(data),
    myCBTArchiveSeo: () => nurseEduSeo.myCBTArchiveSeo(),
    myCBTResultSeo: () => nurseEduSeo.myCBTResultSeo(),
    myCBTNoteSeo: () => nurseEduSeo.myCBTNoteSeo(),
    myCBTPurchaseSeo:  () => nurseEduSeo.myCBTPurchaseSEO()
  },

  test: {
    projectName: 'NurseEdu',
    projectNameKR: '널스에듀',
    clientBaseUrl: 'https://local.nurse-edu.co.kr',
    handleLoginRedirect: (url?: string) => {
      if (url) return `https://local.nurse-edu.co.kr/login?redirect=${encodeURIComponent(url)}`;
      return `https://local.nurse-edu.co.kr/login`;
    },
    logoutRedirectUri: 'https://apiv2-dev.nurse-edu.co.kr/logout',
    apiBaseUrl: 'https://apiv2-dev.nurse-edu.co.kr',
    apiBaseProxyUrl: 'https://apiv2-dev.nurse-edu.co.kr/cbt',
    communityUrl: 'https://nurselink.mnpp.cc',
    idp: 'nurseEdu',
    logo: '/images/logo/nurseEdu/logo.svg',
    logoImagePng: '/images/logo/nurseEdu/logo(800X800).png',
    googleTagManagerId: '',
    kakaoServiceKey: '642ff75d4ef2a326ecb835840c782725',
    handlePurchase: handleNurseEduPurchase,
    header: HeaderNurseEdu,
    footer: FooterNurseEdu,
    defaultSeo: () => nurseEduSeo.defaultSeo() || {},
    examListSeo: (type) => nurseEduSeo.examListSeo(type),
    examDetailSeo: (data) => nurseEduSeo.examDetailSeo(data),
    examTypeSelectionSeo: (data) => nurseEduSeo.examTypeSelectionSeo(data),
    cbtOneQuestionTypeSeo: (data) => nurseEduSeo.cbtOneQuestionTypeSeo(data),
    cbtPracticeTypeSeo: (data) => nurseEduSeo.cbtPracticeTypeSeo(data),
    examResultSeo: (data) => nurseEduSeo.examResultSeo(data),
    examSolutionSeo: (data) => nurseEduSeo.examSolutionSeo(data),
    myCBTArchiveSeo: () => nurseEduSeo.myCBTArchiveSeo(),
    myCBTResultSeo: () => nurseEduSeo.myCBTResultSeo(),
    myCBTNoteSeo: () => nurseEduSeo.myCBTNoteSeo(),
    myCBTPurchaseSeo:  () => nurseEduSeo.myCBTPurchaseSEO()
  },
};
