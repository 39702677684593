export const REACT_QUERY_CONFIG = {
  defaultOptions: {
    queries: {
      staleTime: 0,
      cacheTime: 1000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      suspense: true,
      throwOnError: true,
      retry: false,
    },
  },
};

const REACT_QUILL_MODULE_CONFIG = {
  toolbar: {
    container: [
      [
        { size: ['small', false, 'large', 'huge'] },
        {
          color: [
            '#255bb9',
            '#0075c1',
            '#008cd6',
            '#333d4b',
            '#999999',
            '#c8c8c8',
            '#e5e5e5',
            '#f1f1f1',
            '#f5f5f5',
            '#f8f8f8',
            '#ffffff',
            '#ff4949',
            '#ffecec',
            '#ffb049',
          ],
        },
      ],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
      ['link', 'formula', 'image', 'video'],
    ],
  },
};
