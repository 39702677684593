/**
 * lodash의 debounced를 가져와서, 필요한 메소드 추추 후 ts로 변환한 코드입니다.
 * https://github.com/lodash/lodash/blob/main/lodash.js#L10372
 */

type DebouncedFunction<T extends (...args: any[]) => any> = {
  (...args: Parameters<T>): void;
  cancel: () => void;
};

export default function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
): DebouncedFunction<T> {
  let timerId: ReturnType<typeof setTimeout> | undefined;

  if (typeof func !== "function") {
    throw new TypeError("Expected a function");
  }

  function debounced(...args: Parameters<T>): void {
    if (timerId !== undefined) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      func(...args);
    }, wait);
  }

  debounced.cancel = (): void => {
    if (timerId !== undefined) {
      clearTimeout(timerId);
      timerId = undefined;
    }
  };

  return debounced;
}