/**
 * @nurseEdu
 * 널스에듀 모바일 환경에서 사이드 메뉴를 위한 코드입니다.
 */

import Link from 'next/link';
import { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { StyledIcon } from './StyledIcon';
import Dim from './Dim';
import useIsMobileSize from 'src/app.core/nurseEdu/hook/useIsMobileSize';
import useCurrentUser from 'src/app.core/nurseEdu/hook/useCurrentUser';
import { NURSEEDU_BASE_URL, NURSEEDU_CBT_BASE_URL } from 'src/app.core/nurseEdu/constant/nurseEduConfig';
import { logout } from 'src/app.core/nurseEdu/lib/auth';
import { useRouter } from 'next/router';

interface Props {
  isOpen: boolean;
  closeMenu: () => void;
}

const SideMenu = ({ isOpen, closeMenu }: Props) => {
  const { user, isLogin } = useCurrentUser();
  const { asPath } = useRouter();
  
  const isMobileSize = useIsMobileSize();

  useEffect(() => {
    if (!isMobileSize) {
      closeMenu();
    }
  }, [isMobileSize, closeMenu]);

  return (
    <StyledContainer>
      {isOpen && <Dim close={closeMenu} />}
      <StyledMenu $isOpen={isOpen} $isUser={isLogin}>
        <div className="bl-user">
          <div className="xmark-wrapper">
            <StyledIcon
              $style={{
                desktop: {
                  wrapper: { width: 24, height: 24 },
                  svg: { width: 15.5, height: 15.5 },
                },
              }}
              onClick={closeMenu}
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
              >
                <path
                  d="M5.37752 4.55379L5.45014 4.46967C5.71641 4.2034 6.13307 4.1792 6.42668 4.39705L6.5108 4.46967L12.9805 10.939L19.4501 4.46967C19.743 4.17678 20.2179 4.17678 20.5108 4.46967C20.8037 4.76256 20.8037 5.23744 20.5108 5.53033L14.0415 12L20.5108 18.4697C20.7771 18.7359 20.8013 19.1526 20.5834 19.4462L20.5108 19.5303C20.2445 19.7966 19.8279 19.8208 19.5343 19.6029L19.4501 19.5303L12.9805 13.061L6.5108 19.5303C6.21791 19.8232 5.74303 19.8232 5.45014 19.5303C5.15725 19.2374 5.15725 18.7626 5.45014 18.4697L11.9195 12L5.45014 5.53033C5.18387 5.26406 5.15967 4.8474 5.37752 4.55379L5.45014 4.46967L5.37752 4.55379Z"
                  fill="#222"
                />
              </svg>
            </StyledIcon>
          </div>
          {isLogin && (
            <>
              <Link
                href={`${NURSEEDU_BASE_URL}/mypage/edit-profile`}
                className="user-info"
              >
                <p>{user.username || user.nickname || '익명'}님</p>
                <StyledArrowIcon>
                  <svg
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.73271 6.20694C10.0326 5.92125 10.5074 5.93279 10.7931 6.23271L15.7944 11.4832C16.0703 11.7728 16.0703 12.2281 15.7944 12.5178L10.7931 17.7682C10.5074 18.0681 10.0326 18.0797 9.73271 17.794C9.43279 17.5083 9.42125 17.0336 9.70694 16.7336L14.2155 12.0005L9.70694 7.26729C9.42125 6.96737 9.43279 6.49264 9.73271 6.20694Z"
                      fill="#242424"
                    />
                  </svg>
                </StyledArrowIcon>
              </Link>
            </>
          )}
        </div>
        <nav>
          <>
            {!isLogin && (
              <div>
                <Link href={{
                  pathname: `${NURSEEDU_BASE_URL}/login`,
                  query: { redirect: NURSEEDU_CBT_BASE_URL + asPath }
                }} className="active">
                  로그인
                </Link>
                <Link href={`${NURSEEDU_BASE_URL}/register`}>회원가입</Link>
              </div>
            )}
            <div>
              <Link href={`${NURSEEDU_BASE_URL}/mypage/my-courses`}>내 강의실</Link>
            </div>
            <div>
              <Link href={`${NURSEEDU_BASE_URL}/mypage/purchase-history`}>
                상품 구매 내역
              </Link>
              <Link href={`${NURSEEDU_BASE_URL}/mypage/cancel-history`}>
                환불/취소 내역
              </Link>
              <Link href={`${NURSEEDU_BASE_URL}/mypage/wishlist`}>찜 리스트</Link>
              <Link href={`${NURSEEDU_BASE_URL}/mypage/coupons`}>쿠폰 목록</Link>
              <Link href={`${NURSEEDU_BASE_URL}/mypage/accumulations`}>
                적립금 내역
              </Link>
              <Link href={`${NURSEEDU_BASE_URL}/mypage/edit-profile`}>
                프로필 수정
              </Link>
              <Link href={`${NURSEEDU_BASE_URL}/mypage/manage-addresses`}>
                배송지 관리
              </Link>
              <Link href={`${NURSEEDU_BASE_URL}/mypage/support`}>1:1 문의</Link>
            </div>
            <div>
              <Link href={`${NURSEEDU_CBT_BASE_URL}/mycbt/review`}>나의 시험 CBT</Link>
            </div>
          </>
          {isLogin && (
            <button onClick={() => logout()}
              className="logout"
            >
              로그아웃
            </button>
          )}
        </nav>
      </StyledMenu>
    </StyledContainer>
  );
};

export default SideMenu;

const StyledMenu = styled.div<{ $isOpen: boolean; $isUser: boolean }>`
  position: fixed;
  top: 0;
  right: ${({ $isOpen }) => ($isOpen ? '0' : '-100%')};
  z-index: 100;
  width: ${({ $isUser }) => ($isUser ? '100%' : '300px')};
  height: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  overflow-y: scroll;
  transition: right 0.3s;

  .bl-user {
    padding: 16px 20px 20px;

    ${({ $isUser }) =>
      $isUser &&
      css`
        background-color: #f4f5fa;
      `}

    .xmark-wrapper {
      display: flex;
      justify-content: flex-end;
    }

    .user-info {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 4px;

      p:first-child {
        color: #222;
        font-weight: 600;
        font-size: 18px;
        line-height: 25.2px;
        letter-spacing: -0.025em;
      }

      p:last-child {
        color: #222;
        font-weight: 400;
        font-size: 15px;
        line-height: 21px;
        letter-spacing: -0.025em;
      }
    }
  }

  nav {
    padding: 4px;

    a,
    button {
      padding: 16px;
      color: #222;
      font-weight: 400;
      font-size: 16px;
      line-height: 22.4px;
      letter-spacing: -0.025em;
      background-color: transparent;
    }

    .logout {
      color: #767676;
    }

    .active {
      ${({ $isUser }) =>
        !$isUser &&
        css`
          color: #38459f;
          font-weight: 600;
          font-size: 16px;
          line-height: 22.4px;
          letter-spacing: -0.025em;
        `};
    }

    > div {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #d9dce1;
    }
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

const StyledContainer = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
`;

const StyledArrowIcon = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
`;
