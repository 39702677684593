import * as Sentry from '@sentry/nextjs';

import getErrorMessage from 'utils/etc/getErrorMessage';

class BoundaryError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'BoundaryError';
  }
}

const SentryCaptureBoundaryError = (err: unknown, errorInfo: any) => {
  Sentry.setContext('Boundary Error Detail', {
    errorInfo,
  });

  Sentry.withScope((scope) => {
    scope.setTag('type', 'client');
    scope.setLevel('debug');

    Sentry.captureException(new BoundaryError(getErrorMessage(err)));
  });
};

export default SentryCaptureBoundaryError;
