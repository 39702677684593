import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import ExamApi from 'lib/api/exam';

export const useExamLikeHandler = (
  updateQueryKey?: QueryKey,
  callback?: (oldData: any, viewerHasLiked: boolean, examIdx: number) => void
) => {
  const queryClient = useQueryClient();

  const updateQueryData = (examIdx: number, viewerHasLiked: boolean) => {
    if (callback && updateQueryKey) {
      queryClient.setQueryData(updateQueryKey, (oldData: any) => {
        return callback(oldData, viewerHasLiked, examIdx);
      });
    }
  };

  const creatMutation = (apiMethod: (examIdx: number) => Promise<any>, viewerHasLiked: boolean) => {
    return useMutation({
      mutationFn: (variables: { examIdx: number }) => apiMethod(variables.examIdx),
      onSettled: (_, __, { examIdx }) => {
        updateQueryData(examIdx, viewerHasLiked);
      },
    });
  };

  const likeMutation = creatMutation(ExamApi.likeExam, false);
  const unlikeMutation = creatMutation(ExamApi.unlikeExam, true);

  const handleLikeExam = async (examIdx: number, viewerHasLiked: boolean) => {
    const mutiation = viewerHasLiked ? unlikeMutation.mutateAsync : likeMutation.mutateAsync;
    await mutiation({ examIdx });
  };

  return { handleLikeExam };
};
