/**
 * @nurseEdu
 * 널스에듀에서 사용하는 error handler
 */

import { isAxiosError } from "axios";

const ERROR_MESSAGE: { [key: number]: string } = {
  400: "잘못된 형식의 요청입니다.",
  401: "로그인이 필요한 서비스입니다.\n로그인 후 다시 시도해주세요.",
  403: "로그인이 필요한 서비스입니다.\n로그인 후 다시 시도해주세요.",
  404: "찾을 수 없습니다.",
  500: "알 수 없는 에러가 발생했습니다.",
};

const STATUS_CODE_MESSAGE: { [key: string]: string } = {
  O8002: "최대 구매 가능 개수를 초과하였습니다.",
  O8003: "최대 구매 가능 개수를 초과하였습니다.",
  O8004: "장바구니에 동일한 강의가 담겨있습니다. 장바구니를 확인해주세요.", // 장바구니 최대 가능 개수 초과 시
  CART0001:
    "장바구니에 담을 수 있는 최대 상품 수를 초과하였습니다.\n담겨있는 상품 삭제 후 추가하실 수 있습니다.",
};

export const handleError = (
  error: unknown,
  customMessage: { [key: number]: string } = {},
  redirectUrl?: string
) => {
  if (!error) return;

  if (error instanceof Error) {
    if (isAxiosError(error)) {
      const status = error.response?.status ?? 500;
      const code =
        error.response?.data.code ?? error.response?.data.error.code ?? "";

      const message = 
        STATUS_CODE_MESSAGE[code] ??
        customMessage[status] ??
        ERROR_MESSAGE[status] ??
        "알 수 없는 에러"

      alert(message);

      if ((status === 401 || status === 403) && redirectUrl) {
        window.location.href = `/login?redirect=${redirectUrl}`;
      }

      return;
    }

    if (error.name === "TimeoutError") {
      alert("요청 시간이 초과되었습니다.");
      return;
    }
    alert(error.message);
    return;
  }

  alert(`에러가 발생했습니다. ${error}`);
};
