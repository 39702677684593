import Router from "next/router";
import { NURSEEDU_API_BASE_URL, NURSEEDU_CBT_BASE_URL, NURSEEDU_COMMUNITY_BASE_URL } from "../constant/nurseEduConfig";

/**
 * @description
 * 로그아웃 시에 커뮤니티 쪽도 로그아웃 시킵니다.
 */
export const logout = (params?: { url: string; asPath: string }) => {

  const path = params?.asPath || Router.asPath;
  const redirectUri = params?.url || NURSEEDU_CBT_BASE_URL + path;

  const logoutUrl = new URL(`${NURSEEDU_API_BASE_URL}/auth/logout`);
  logoutUrl.searchParams.append("redirectUri", redirectUri);

  const communityLogoutUrl = new URL(
    `${NURSEEDU_COMMUNITY_BASE_URL}/redirect-logout`
  );
  communityLogoutUrl.searchParams.append("redirectUri", logoutUrl.toString());

  window.location.href = communityLogoutUrl.toString();
};

/**
 * @description
 * 널스에듀에서 커뮤니티로 이동할 때 커뮤니티 쪽 로그인을 동기화 합니다.
 */
export const getCommunityLoginUrl = (params?: { url: string; asPath: string }) => {
  const path = params?.asPath || "/";
  const redirectUri = params?.url || NURSEEDU_COMMUNITY_BASE_URL + path;

  const query = `redirectUrl=${encodeURIComponent(redirectUri)}`;
  const communityloginUrl = `${NURSEEDU_API_BASE_URL}/community?${query}`;

  return communityloginUrl;
}