// 사용자 조회 api 타입

export enum UserType {
  NORMAL = 'NORMAL',
}

export type CurrentUserQuertVariables = {
  notificationType: 'NOTIFICATION';
};

export type CurrentUserQueryResult = {
  currentUser: {
    id: string;
    type: UserType;
    username?: string;
    scrapCount?: number;
    notificationUnreadCount?: number;
    channel?: {
      id: string;
      url: string;
      deactivatedAt?: string | null;
    };
    tokenSet?: {
      accessToken?: string;
    };
  } | null;
};

// 고객 문의 api 타입
export type CreateSuggestionMutationVariables = {
  input: {
    email: string;
    content: string;
    attachmentIds: Array<string>;
    deviceInfo: 'MOBILE' | 'PC';
  };
};

export type CreateSuggestionMutationResult = {
  suggestion: {
    email: string;
    content: string;
  };
};

// 첨부파일 prepare 업로드 api 타입
export type AttachmentPrepareUploadMutationVariables = {
  name: string;
  size: number;
  type: string;
  path?: string;
};

export type AttachmentPrepareUploadMutationResult = {
  attachmentPrepareUpload: {
    attachment: {
      id: string;
      url: string;
    };
    signedURL: string;
  };
};

// 첨부파일 complete 업로드 api 타입
export type AttachmentCompleteUploadMutationVariables = {
  attachmentID: string;
};

export type AttachmentCompleteUploadMutationResult = {
  attachmentCompleteUpload: {
    attachment: {
      id: string;
      url: string;
      name: string;
      type: string;
      size: string;
    };
  };
};

// 자소서 건수 조회 api 타입
type CoverLetterCountQueryResult = {
  coverLetterTotalCount?: number;
};

// 임베드 게시글 조회 api 타입
export type EmbeddablePost = {
  id: string;
  title: string;
  contentText: string;
  createdAt: string;
  nickname: string;
  views: number;
  commentCount: number;
  containsImage: boolean;
  containsYoutube: boolean;
  board: {
    title: string;
    slug: string;
  };
};

export type EmbeddablePostsResult = {
  embeddablePosts: {
    totalCount: number;
    nodes: EmbeddablePost[];
  };
};

export type EmbeddablePostsVariables = {
  uri: string;
  page: number;
  pageSize: number;
};

// 커뮤니티 게시판 정보 조회 api 타입
export type CommunityBoardBySlugResult = {
  boardBySlug: {
    id: string;
    slug: string;
    menu: {
      id: string;
    };
  };
};

export type CommunityBoardBySlugVariables = {
  boardSlug: string;
};

// 커뮤니티 게시글 즐겨찾기 추가 api 타입
export type CommunityAddBookmarkMutationResult = {
  addBookmark: {
    success: boolean;
    bookmarkable: {
      id: string;
      viewerHasBookmarked: boolean;
    };
  };
};

export type CommunityAddBookmarkMutationVariables = {
  menuID: string;
};
