import React from 'react';
import styled from 'styled-components';
import Pagination from 'rc-pagination';
import ArrowPagination from 'src/app.feature/academy/component/icon/arrow-pagination.svg';
import useResponsive from 'utils/OneQuestion/hooks/useResponsive';
import { theme } from 'app.styled/theme';

type PaginationProps = {
  total: number;
  pageSize: number;
  current: number;
  handlePageChange: (pageNumber: number) => void;
};

const CbtListTablePagination: React.FC<PaginationProps> = ({
  total,
  pageSize,
  current,
  handlePageChange,
}) => {
  const { isMobile } = useResponsive(768);

  return (
    <PaginationWrapper>
      {(!isMobile || (isMobile && total > 10)) && (
        <Pagination
          total={total}
          pageSize={pageSize}
          current={current}
          onChange={handlePageChange}
          prevIcon={
            <button>
              <ArrowPagination />
            </button>
          }
          nextIcon={
            <button>
              <ArrowPagination />
            </button>
          }
          showLessItems={false}
          itemRender={(page, type, originalElement) => {
            if (type === 'jump-prev' || type === 'jump-next') {
              return <span>...</span>;
            }
            return originalElement;
          }}
        />
      )}
    </PaginationWrapper>
  );
};

export default CbtListTablePagination;

const PaginationWrapper = styled.div`
  .rc-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px auto 200px;
    @media ${theme.device.mobile} {
      margin: 20px auto;
      padding: 0;
    }
  }

  .rc-pagination-item,
  .rc-pagination-prev,
  .rc-pagination-next,
  .rc-pagination-total-text,
  .rc-pagination-jump-prev,
  .rc-pagination-jump-next {
    a,
    button,
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 39px;
      height: 39px;
      border-radius: 5px;
      border: 1px solid transparent;
      background-color: transparent;
      font-size: 15px;
      font-weight: 500;
      color: #ddd;
      overflow: hidden;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        background-color: #eee;
      }
    }

    &.rc-pagination-item-active {
      a {
        color: #01a0ff;
      }
    }
  }

  .rc-pagination-prev,
  .rc-pagination-next {
    a,
    button {
      background: #eee;
    }

    svg {
      path {
        stroke: #fff;
      }
    }

    &:hover {
      svg {
        path {
          stroke: #333;
        }
      }
    }
  }

  .rc-pagination-prev {
    svg {
      transform: rotate(180deg);
    }
  }

  button {
    margin: 0 5px;
    padding: 5px 10px;
    background-color: #fff;
    cursor: pointer;
    font-size: 15px;
    border-radius: 4px;
    color: #ddd;

    &:hover {
      background-color: #f0f0f0;
    }

    &.active {
      color: #01a0ff;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;
      color: #bbb;
    }
  }
`;
