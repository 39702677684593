/**
 * @nurseEdu
 * 해당 코드는 현재 창이 모바일 크기인지 판단하기 위해 사용됩니다.
 * 단순 모바일 기기를 식별하기 위해서가 아닌, 데스크톱 환경에서도 창이 모바일 크기인지 아닌지 판단하기 위해 사용됩니다.
 */

import { useState, useEffect, useCallback, useMemo } from 'react';
import debounce from '../lib/debounce';

const useIsMobileSize = () => {
  const [isMobile, setIsMobile] = useState<boolean | null>(null);

  const mediaQueryList = useMemo(
    () =>
      typeof window !== 'undefined'
        ? window.matchMedia(`(max-width: 959px)`)
        : null,
    []
  );

  const handleMediaQueryChange = useCallback((event: MediaQueryListEvent) => {
    setIsMobile(event.matches);
  }, []);

  const debouncedHandleMediaQueryChange = useMemo(
    () => debounce(() => handleMediaQueryChange, 100),
    [handleMediaQueryChange]
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsMobile(mediaQueryList?.matches ?? false);
    }
  }, [mediaQueryList]);

  useEffect(() => {
    if (!mediaQueryList) {
      return;
    }

    mediaQueryList.addEventListener('change', debouncedHandleMediaQueryChange);

    return () => {
      mediaQueryList.removeEventListener(
        'change',
        debouncedHandleMediaQueryChange
      );
      debouncedHandleMediaQueryChange.cancel();
    };
  }, [mediaQueryList, debouncedHandleMediaQueryChange]);

  return isMobile;
};

export default useIsMobileSize;
